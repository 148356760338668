<template>
  <v-card v-if="isMounted" style="min-height: 80px;">
    <v-card-title class="primary white--text justify-space-between"
                  style="font-size: small; margin-bottom: 12px; padding-top: 0 !important;
                         padding-left: 10px !important; padding-bottom: 0 !important;">
      Q{{questionNumber + 1}} {{examMeta.institution}} {{examMeta.year}} <v-spacer/> {{examMeta.subject}}
<!--      <v-btn v-if="editMode" small icon style="float: right; color: #ffffff;" @click="toggleTextOverlay"><v-icon>mdi-text-box-plus-outline</v-icon></v-btn>-->
    </v-card-title>
    <div v-show="!isCollapsed">
      <v-card-text v-if="editMode">
<!--        <v-overlay absolute :value="textOverlay" opacity="0.85" color="secondary">-->
<!--          <v-card color="primary" style="width: 40vw">-->
<!--            <v-card-title>Inserção de Texto externo</v-card-title>-->
<!--            <v-card-text>-->
<!--              <v-textarea label="Texto" solo dense color="accent" v-model="questionTexto" no-resize></v-textarea>-->
<!--            </v-card-text>-->
<!--            <v-card-actions style="align-items: center">-->
<!--              <v-btn large icon color="error" @click="closeTextOverlay"><v-icon>mdi-close</v-icon></v-btn>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn large icon color="accent" @click="toggleTextOverlay"><v-icon>mdi-check</v-icon></v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-overlay>-->

        <v-overlay absolute :value="linkOverlay" opacity="0.85" color="secondary">
          <v-card color="primary" width="22em">
            <v-card-title>Inserção de Link</v-card-title>
            <v-card-text class="d-flex justify-space-between">
              <v-text-field label="Inserir link" solo dense v-model="newLink" color="white"
                            :rules="[v => !v || Boolean(v.match(/(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/)) || 'Link Inválido!']">
              </v-text-field>
              <v-btn large icon color="error" @click="linkOverlay = !linkOverlay"><v-icon>mdi-close</v-icon></v-btn>
              <v-btn large icon color="accent" @click="createLink"><v-icon>mdi-upload</v-icon></v-btn>
            </v-card-text>
          </v-card>
        </v-overlay>

        <tiptap-vuetify
          v-model="questionEnunciados[0]"
          placeholder="Inserir enunciado..."
          :extensions="extensions"
          :native-extensions="nativeExtensions"
          :toolbar-attributes="{ color: 'secondary' }"
          :editor-properties="{ disableInputRules: true, disablePasteRules: true }"
          style="padding-bottom: 2vh"
          :card-props="{ color: 'secondary' }"
          @keydown="changePreview"
          @paste="pastePlainText($event)"
        >
          <div slot="toolbar" slot-scope="{ buttons, commands, isActive }">
            <template>
              <v-btn small icon style="font-size: 16px" @click="commands.undo">
                <v-icon>mdi-undo-variant</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.redo">
                <v-icon>mdi-redo-variant</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.heading({level: 1})">
                <v-icon>mdi-format-size</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.bold">
                <v-icon>mdi-format-bold</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.italic">
                <v-icon>mdi-format-italic</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.bullet_list">
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.ordered_list">
                <v-icon>mdi-format-list-numbered</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="triggerLinkDialog(commands.link)">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.paragraph">
                <v-icon>mdi-format-pilcrow</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px"
                     @click="commands.createTable({rowsCount: 3, colsCount: 3 })">
                <v-icon>mdi-table</v-icon>
              </v-btn>

              <span v-if="isActive.table()">
              <v-btn small icon style="font-size: 16px" @click="commands.deleteTable">
                <v-icon>mdi-table-cancel</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.addColumnBefore">
                <v-icon>mdi-table-column-plus-before</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.addColumnAfter">
                <v-icon>mdi-table-column-plus-after</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.deleteColumn">
                <v-icon>mdi-table-column-remove</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.addRowBefore">
                <v-icon>mdi-table-row-plus-before</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.addRowAfter">
                <v-icon>mdi-table-row-plus-after</v-icon>
              </v-btn>
              <v-btn small icon style="font-size: 16px" @click="commands.deleteRow">
                <v-icon>mdi-table-row-remove</v-icon>
              </v-btn>
          </span>
            </template>
          </div>
        </tiptap-vuetify>

        <v-textarea v-model="questionDados" label="Dados" color="accent" @change="changePreview" no-resize auto-grow rows="3"
                    hint="Sintaxe LaTeX, separados por vírgula. Pode deixar em branco." solo></v-textarea>

        <v-switch style="margin: 0 !important;" color="accent" v-model="questionSwitch"
                  :label="questionSwitch?'Discursiva':'Múltipla Escolha'" @change="toggleAlternativas"></v-switch>
        <v-checkbox style="margin: -16px 0 0 0 !important;" color="accent" v-model="isPendingImages"
                    label="A questão tem imagens pendentes"></v-checkbox>
        <v-checkbox v-if="!questionSwitch" style="margin: -16px 0 0 0 !important;" color="accent" v-model="questionCEFlag"
                    label="Questão tipo Certo/Errado" @change="toggleCEAlternativas"></v-checkbox>
        <v-container style="margin-top: -2vh" fluid>
          <v-card flat class="card-in-card" v-if="!questionSwitch">
            <v-card-title>Respostas</v-card-title>
            <v-card-text v-for="(alternative, i) in questionAlternativas" :key="i">
              <v-text-field @change="changePreview" v-model="alternative.value" :prefix="alphabet[i]" style="margin-bottom: -5vh"
                            :disabled="questionCEFlag" dense solo color="accent" required></v-text-field>
            </v-card-text>

            <v-card-actions style="margin-top: 1.5vh;">
              <v-btn v-if="!questionCEFlag && questionAlternativas.length<7" @click="addAlternative" class="success" depressed>Adicionar</v-btn>
              <v-btn v-if="!questionCEFlag && questionAlternativas.length>0" @click="removeAlternative" class="error" depressed>Remover</v-btn>
            </v-card-actions>
          </v-card>
          <v-row dense v-else>
            <v-col cols="12" style="margin-left: -12px;">
              <v-btn @click="addQuestionItem" class="success" small depressed style="margin-right: 8px;">Adicionar Item</v-btn>
              <v-btn v-if="questionEnunciados[1].length" @click="removeQuestionItem" class="error" small depressed>Remover Item</v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-card flat style="margin-bottom: 2vh" class="card-in-card" v-for="(questionItem, itemIndex) in questionEnunciados[1]" :key="itemIndex">
          <v-card-title>
            Item {{ alphabet[itemIndex] }}
          </v-card-title>
          <v-card-text>
            <tiptap-vuetify
              v-model="questionEnunciados[1][itemIndex]"
              placeholder="Inserir enunciado..."
              :extensions="extensions"
              :native-extensions="nativeExtensions"
              :toolbar-attributes="{ color: 'secondary' }"
              :editor-properties="{ disableInputRules: true, disablePasteRules: true }"
              :card-props="{ color: 'secondary' }"
              @keydown="changePreview"
              @paste="pastePlainText($event)"
            >
              <div slot="toolbar" slot-scope="{ buttons, commands, isActive }">
                <template>
                  <v-btn small icon style="font-size: 16px" @click="commands.undo">
                    <v-icon>mdi-undo-variant</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.redo">
                    <v-icon>mdi-redo-variant</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.bold">
                    <v-icon>mdi-format-bold</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.italic">
                    <v-icon>mdi-format-italic</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.bullet_list">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.ordered_list">
                    <v-icon>mdi-format-list-numbered</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="triggerLinkDialog(commands.link)">
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.paragraph">
                    <v-icon>mdi-format-pilcrow</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px"
                         @click="commands.createTable({rowsCount: 3, colsCount: 3 })">
                    <v-icon>mdi-table</v-icon>
                  </v-btn>

                  <span v-if="isActive.table()">
                  <v-btn small icon style="font-size: 16px" @click="commands.deleteTable">
                    <v-icon>mdi-table-cancel</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.addColumnBefore">
                    <v-icon>mdi-table-column-plus-before</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.addColumnAfter">
                    <v-icon>mdi-table-column-plus-after</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.deleteColumn">
                    <v-icon>mdi-table-column-remove</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.addRowBefore">
                    <v-icon>mdi-table-row-plus-before</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.addRowAfter">
                    <v-icon>mdi-table-row-plus-after</v-icon>
                  </v-btn>
                  <v-btn small icon style="font-size: 16px" @click="commands.deleteRow">
                    <v-icon>mdi-table-row-remove</v-icon>
                  </v-btn>
                </span>
                </template>
              </div>
            </tiptap-vuetify>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else>
        <v-container>
          <v-row class="d-flex flex-row-reverse" style="margin-bottom: 1vh">
            <v-btn v-if="questionTexto" disabled x-small>Texto</v-btn>
          </v-row>
          <v-row v-if="questionEnunciados[0]" style="font-size: 1rem; text-align: justify" class="d-block enunciado tex2jax_process text--primary"
                 v-html="questionEnunciados[0]">
          </v-row>
          <v-row v-for="(itemEnunciado, itemEnunciadoIndex) in questionEnunciados[1]" :key="itemEnunciadoIndex">
            <v-container v-if="itemEnunciado" class="d-block enunciado tex2jax_process text--primary" style="font-size: 1rem"
                         v-html="itemEnunciado">
            </v-container>
          </v-row>
          <v-row v-if="questionDados">
            <v-container style="border: 1px solid #7E5A9B;"
                         class="enunciado tex2jax_process text--primary"
                         v-html="questionDados.match(/(?:\$+[^$\\]*(?:\\[\s\S][^$\\]*)*\$+|[^,])+/g).map(s => s.trim()).join('<br/>')">
            </v-container>
          </v-row>
          <br>
          <v-row>
            <v-col>
              <v-radio-group v-if="questionAlternativas[0]" v-model="alternativesModel" style="margin-top: 0; padding-top: 0">
                <v-radio :on-icon="alphabetIcons[altIndex]" :off-icon="alphabetIcons[altIndex]"
                         class="d-block enunciado tex2jax_process text--primary" v-for="(alternative, altIndex) in questionAlternativas"
                         :key="altIndex" :value="alphabet[altIndex]" :label="alternative.value" :color="alphabet[altIndex]?'accent':'tertiary'"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { TiptapVuetify, History, Heading, Bold, Italic, Paragraph, BulletList, OrderedList, ListItem, HardBreak, Link } from 'tiptap-vuetify'
import { Table, TableCell, TableHeader, TableRow, Image } from 'tiptap-extensions'
export default {
  name: "EditQuestionSubmission",
  props: ['questionMeta', 'examMeta', 'questionNumber', 'editMode', 'externalGabarito', 'isCollapsed', 'savedData'],
  components: { TiptapVuetify },
  data: () => ({
    rules: [files => !files || !files.some(file => file.size > 5242880) || 'O tamanho dos anexos não deve exceder 5 MB!'],
    valid: false,
    alphabet: ["a)", "b)", "c)", "d)", "e)", "f)", "g)"],
    alphabetIcons: ["mdi-alpha-a-circle","mdi-alpha-b-circle","mdi-alpha-c-circle","mdi-alpha-d-circle","mdi-alpha-e-circle","mdi-alpha-f-circle","mdi-alpha-g-circle"],
    awaitingTyping: false,
    alternativesModel: null,

    isMounted: false,
    questionEnunciados: [null, []],
    questionGabaritos: [null, []],
    questionAlternativas: [],
    questionTexto: null,
    questionDados: null,

    textOverlay: false,
    questionSwitch: null,
    questionCEFlag: null,
    isPendingImages: false,
    linkOverlay: false, editorLinkCommand: null, newLink: null,

    extensions: [
      History, [Heading, {options: {levels: [1]}}], Bold, Italic, ListItem,
      BulletList, OrderedList, Link, Paragraph, HardBreak
    ],
    nativeExtensions: [
      new Table({ resizable: true }), new TableHeader(),
      new TableCell(), new TableRow(), new class extends Image { get plugins() { return false; } } ()
    ],
  }),
  mounted() {
    const qMeta = this.questionMeta;
    if (qMeta.disc) {
      this.questionSwitch = true;
      this.questionCEFlag = false;
      for (let itemIdx=0; itemIdx < qMeta.numItems; itemIdx++) {
        this.questionEnunciados[1][itemIdx] = null; this.questionGabaritos[1][itemIdx] = null;
      }
    } else if (qMeta.isCE) {
      this.questionSwitch = false;
      this.questionCEFlag = true;
      this.questionAlternativas = [{value: "Certo"},{value: "Errado"}];
    } else {
      this.questionSwitch = false;
      this.questionCEFlag = false;
      for (let altIdx=0; altIdx < qMeta.numItems; altIdx++) {
        this.questionAlternativas.push({value: null});
      }
    }
    if (this.savedData && Object.keys(this.savedData).length) {
      this.alternativesModel = this.savedData.alternativesModel; this.isPendingImages = this.savedData.isPendingImages;
      this.newLink = this.savedData.newLink; this.questionAlternativas = this.savedData.questionAlternativas;
      this.questionCEFlag = this.savedData.questionCEFlag; this.questionDados = this.savedData.questionDados;
      this.questionEnunciados = this.savedData.questionEnunciados; this.questionGabaritos = this.savedData.questionGabaritos;
      this.questionSwitch = this.savedData.questionSwitch; this.questionTexto = this.savedData.questionTexto;
      // this.textOverlay = this.savedData.textOverlay;
      this.valid = this.savedData.valid;
    }
    this.isMounted = true;
    this.$emit('question-mounted', this.submitQuestionObject); // Pass function to parent, so it can be called later
  },
  watch: { externalGabarito: function(val) { this.questionGabaritos[0] = val || null } },
  methods: {
    pastePlainText(e) {
      e.preventDefault();
      document.execCommand("insertHTML", false, e.clipboardData.getData("text/plain"));
    },
    changePreview() { if (!this.awaitingTyping) {
      setTimeout(() => { this.$forceUpdate(); this.reRender(); this.awaitingTyping = false }, 400)
    } this.awaitingTyping = true },
    reRender() {if ('MathJax' in window) { this.$nextTick(function() { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.body]) }) }},
    // toggleTextOverlay() { this.textOverlay = !this.textOverlay },
    // closeTextOverlay() {this.toggleTextOverlay(); this.questionTexto = null},
    addAlternative() { this.questionAlternativas.push({value: null}); this.$emit('increment-item', 1); },
    removeAlternative() { this.questionAlternativas.pop(); this.$emit('increment-item', -1); },
    addQuestionItem() { this.questionEnunciados[1].push(null); this.questionGabaritos[1].push(null); this.$emit('increment-item', 1); this.$forceUpdate(); },
    removeQuestionItem() { this.questionEnunciados[1].pop(); this.questionGabaritos[1].pop(); this.$emit('increment-item', -1); this.$forceUpdate(); },
    toggleAlternativas() {
      if(this.questionSwitch){ this.questionAlternativas = []; this.questionCEFlag = false; }
      else { this.questionEnunciados[1] = []; this.questionGabaritos[1] = []; }
      this.$emit('toggle-disc', this.questionSwitch); this.$emit('toggle-ce', false); this.$emit('set-item', 0);
      this.$forceUpdate();
    },
    toggleCEAlternativas() {
      if(this.questionCEFlag){ this.questionAlternativas = [{value: 'Certo'},{value: 'Errado'}]; this.$emit('set-item', 2); }
      else { this.questionAlternativas = []; this.$emit('set-item', 0); } this.$emit('toggle-ce', this.questionCEFlag);
      this.$forceUpdate();
    },
    triggerLinkDialog(command){ this.linkOverlay = !this.linkOverlay; this.editorLinkCommand = command; },
    createLink() { const url = this.newLink.replace(/\s/g, ''); if (url) { this.editorLinkCommand({ href: url }); this.linkOverlay = false; this.newLink = null; } },
    submitQuestionObject(eid) {
      let questionID = (this.questionNumber%2===0) ? (eid + String(3*this.questionNumber+7)) : (eid + String(7*this.questionNumber+19));
      const questionObject = {
        id: questionID,
        data: {
          alternatives: JSON.stringify(this.questionAlternativas),
          answers: [],
          comments: [],
          dados: this.questionDados,
          difficulty: null,
          enunciados: JSON.stringify(this.questionEnunciados),
          examID: eid,
          flagCE: Boolean(this.questionCEFlag),
          gabaritos: JSON.stringify(this.questionGabaritos),
          images: JSON.stringify([]),
          meta: this.examMeta,
          numCorrectResponders: 0,
          numResponders: 0,
          pendingImages: Boolean(this.isPendingImages),
          questionNumber: this.questionNumber + 1,
          relatedText: this.questionTexto,
          tags: {}
        }
      }; this.$emit('submit-q-object', questionObject);
    },
  },
};
</script>

<style>
  .theme--dark.card-in-card { background-color: #2b2a2f !important; }
  .theme--light.card-in-card { background-color: #f4f4f4 !important; }
  .tiptap-vuetify-editor__content th { background-color: rgb(200, 200, 200, 0.2) !important; }
</style>
